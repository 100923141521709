import Listing from './Listing'
import SEO from './SEO'
import Header from './Header'
import Layout from './Layout'
import Wrapper from './Wrapper'
import Feed from './Feed'
import PostContent from './PostContent'
import PostImages from './PostImages'
import PostThumbnails from './PostThumbnails'
// import Footer from './Footer'
// import SliceZone from './SliceZone'
// import Title from './Title'

export { Layout, Listing, SEO, Wrapper, Header, Feed, PostContent, PostImages, PostThumbnails }
