import React from "react"
import Img from 'gatsby-image'
import { Link } from 'gatsby'

function PostThumbnails(props) {
  return (
    <>
    {props.image_set.map((set, i) => (
      <>
        <Link to={props.uid} className="post_thumbnail" key={i}><Img fluid={set.image_1.localFile.childImageSharp.fluid} fadeIn="true" /></Link>
        { set.layout.includes('double') ?
          (<Link to={props.uid} className="post_thumbnail" key={i}><Img fluid={set.image_2.localFile.childImageSharp.fluid} fadeIn="true" /></Link>) :
          ('')
        }
      </>
    ))}
    </>
  )
}

export default PostThumbnails