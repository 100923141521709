import React from "react"
import Img from 'gatsby-image'

function PostContent(props) {
  return (
    <article className='post_content'>
      <figure className='inner'>
        {props.image_set.map((set, i) => (
          <div className={'image_set ' + set.layout} key={i}>
            <Img fluid={set.image_1.localFile.childImageSharp.fluid} fadeIn="true" />
            { set.layout.includes('double') ?
              (<Img fluid={set.image_2.localFile.childImageSharp.fluid} fadeIn="true" />) :
              ('')
            }
          </div>  
        ))}
        <figcaption className='info'>
          <p className='date'>{props.date}</p>
          <h2 className='title'>{props.title}</h2>
        </figcaption>
      </figure>
    </article>
  )
}

export default PostContent