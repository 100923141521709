import React, { Component } from 'react'
import PropTypes from 'prop-types'

class Feed extends Component {
  render() {
    const { children } = this.props
    return (
      <section className="feed">
        {children}
      </section>
    )
  }
}

export default Feed

Feed.propTypes = {
  children: PropTypes.node.isRequired,
}
