const theme = {
  colors: {
    primary: '#333',
    bg: '#fff',
  },
  breakpoints: {
    xs: '400px',
    s: '600px',
    m: '900px',
    l: '1200px',
  },
}

export default theme
