import React, { Component } from 'react'
import PropTypes from 'prop-types'

class Header extends Component {
  render() {
    const { children } = this.props

    // https://www.w3schools.com/howto/howto_js_navbar_hide_scroll.asp
    if (typeof window !== 'undefined') {
      let initial = 60;
      let prevScrollPos = window.pageYOffset;

      window.onscroll = function () {
        //const maxScroll = document.body.clientHeight - window.innerHeight;
        const main_header = document.getElementById("main_header");
        console.log(document.body.clientHeight - window.innerHeight);
        let currentScrollPos = window.pageYOffset;

        // Scrolling down
        if ( currentScrollPos > prevScrollPos ) {
          if ( currentScrollPos  > initial ) {
            main_header.classList.add('scrolled');
          }
          if ( currentScrollPos > prevScrollPos ) {
            main_header.classList.remove('reveal');
          }
        } else {
        // Scrolling up
          if ( currentScrollPos <= 0 ) {
            main_header.classList.remove('scrolled');
          } 
          if (currentScrollPos < prevScrollPos ) {
            main_header.classList.add('reveal');
          }
        }
        prevScrollPos = currentScrollPos;
      }


      // let prevScrollpos = window.pageYOffset;
      // window.onscroll = function () {
      //   const maxScroll = document.body.clientHeight - window.innerHeight;
      //   let currentScrollPos = window.pageYOffset;
      //   //console.log(currentScrollPos)
      //   if (
      //     // first line is hits
      //     ((maxScroll > 0 && prevScrollpos > currentScrollPos && prevScrollpos <= maxScroll) 
      //     || (maxScroll <= 0 && prevScrollpos > currentScrollPos)
      //     || (prevScrollpos <= 0 && currentScrollPos <= 0)) && currentScrollPos < initial

      //     ) {
      //     document.getElementById("main_header").classList.remove("scrolled"); // change to "reveal"
      //   } else if (currentScrollPos > initial) {
      //     document.getElementById("main_header").classList.add("scrolled"); // change to "reveal"
      //   }
      //   prevScrollpos = currentScrollPos;
      // }
    }


    // if scrolling down... 
      // if past initial, set scrolled & remove reveal
    // if scrolling up...
      // if past initial, add reveal
      // if hit the top, remove scrolled and remove



    return (
      <header id='main_header'>
        {children}
      </header>
    )
  }
}

export default Header

Header.propTypes = {
  children: PropTypes.node.isRequired,
}